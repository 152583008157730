<template>
  <svg :class="classes">
    <use class="vac-icon__path" :href="`${icons}#vac-ui-icon-${name}`" />
  </svg>
</template>
<script setup>
import { defineProps, computed } from 'vue-demi';
import icons from '../assets/images/icons.svg';
import IconList from '../utils/IconList';

const BLOCK_SELECTOR = 'vac-icon';

const props = defineProps({
  /**
   * Name of the icon to display
   */
  name: {
    type: String,
    required: true,
    validator: (value) => IconList.includes(value)
  },
  /**
   * Apply hite color
   */
  white: {
    type: Boolean
  },
  /**
   * Apply small size
   */
  small: {
    type: Boolean
  }
});

const classes = computed(() => [
  BLOCK_SELECTOR,
  { [`${BLOCK_SELECTOR}--white`]: props.white, [`${BLOCK_SELECTOR}--small`]: props.small }
]);
</script>
<style scoped lang="postcss">
.vac-icon {
  width: 1rem;
  height: 1rem;
  fill: var(--vac-color-black);

  &--white {
    fill: var(--vac-color-white);
  }

  &--small {
    width: 0.5rem;
    height: 0.5rem;
  }
}
</style>
