<template>
  <div class="vac-tabs-menu">
    <div class="vac-tabs-menu__container">
      <button
        v-for="(tab, index) of tabs"
        :key="`tab-${index}`"
        :class="tabClasses(tab)"
        @click="switchTab(index)"
      >
        {{ tab.label }}
      </button>
    </div>
  </div>
</template>

<script setup>
const TAB_CLASS = 'vac-tabs-menu__tab';
const props = defineProps({
  tabs: {
    type: Array
  },
  activeTab: {
    type: Object
  }
});

const tabClasses = (tab) => {
  return [
    TAB_CLASS,
    {
      [`${TAB_CLASS}--active`]: tab === props.activeTab
    }
  ];
};

const emits = defineEmits(['switch']);

const switchTab = (index) => {
  emits('switch', index);
};
</script>

<style scoped lang="postcss">
@import '../assets/css/_breakpoints.css';
@import '../assets/css/_mixins.css';
.vac-tabs-menu {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @add-mixin breakpoint-tablet {
    overflow: initial;
  }
  &__container {
    display: flex;
    width: fit-content;
    gap: 2rem;
    @add-mixin breakpoint-tablet {
      width: 100%;
      justify-content: center;
    }
  }
  &__tab {
    flex-shrink: 0;
    background: transparent;
    border: 0;
    font-family: var(--vac-font-prelo);
    font-weight: 300;
    font-size: 1rem;
    &--active {
      font-weight: bold;
    }
  }
}
</style>
