<template>
  <div :class="blockClasses">
    <input
      type="radio"
      :name="name"
      :id="`${name}-${inputValue}`"
      class="vac-ui-sr-only vac-radio__input"
      :value="inputValue"
      v-model="binding"
    />
    <label class="vac-radio__label" :for="`${name}-${inputValue}`">{{ label }}</label>
  </div>
</template>
<script setup>
import { defineProps, computed, defineEmits } from 'vue-demi';
import { vModelEmits, vModelProps } from '../composable/useVModelBindings';

const BLOCK_SELECTOR = 'vac-radio';
const props = defineProps({
  appearance: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'video'].includes(value)
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  inputValue: {
    type: String,
    required: true
  },
  ...vModelProps
});
const emits = defineEmits(vModelEmits);

const isVue3PropUsed = computed(() => typeof props.modelValue !== 'undefined');

const binding = computed({
  get() {
    return isVue3PropUsed.value ? props.modelValue : props.value;
  },
  set() {
    if (isVue3PropUsed.value) {
      emits('update:modelValue', props.inputValue);
    } else {
      emits('input', props.inputValue);
    }
  }
});

const blockClasses = computed(() => [BLOCK_SELECTOR, `${BLOCK_SELECTOR}--${props.appearance}`]);
</script>
<style scoped lang="postcss">
.vac-radio {
  $block-selector: .vac-radio;
  font-family: var(--vac-font-prelo);

  &--video {
    display: flex;
    align-items: stretch;
  }

  &__input:checked + $(block-selector) {
    &__label {
      &::after {
        opacity: 1;
      }

      $(block-selector)--video & {
        background-color: var(--vac-color-black);
        border-radius: 2rem;
        font-weight: bold;
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    cursor: pointer;
    gap: 1rem;
    font-weight: 500;

    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid var(--vac-color-black);
      border-radius: 50%;
      box-sizing: border-box;
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      width: 1.125rem;
      height: 1.125rem;
      background-color: var(--vac-color-black);
      border-radius: 50%;
      transition: opacity 250ms;
      opacity: 0;
    }

    $(block-selector)--video & {
      color: var(--vac-color-white);
      padding: 0.6rem 1rem;
      align-items: center;
      line-height: 1;
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
</style>
