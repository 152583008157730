<template>
  <div class="vac-input">
    <div :class="containerClasses">
      <label v-show="!hideLabel" class="vac-textarea__label" :for="name">{{ label }}</label>
      <div class="vac-textarea__input-container">
        <textarea
          v-model="binding"
          ref="input"
          class="vac-textarea__input"
          :id="name"
          :name="name"
          :disabled="disabled"
          :required="required"
          :readonly="readonly"
          :max-length="maxLength"
          @input="handleHeight"
          @focus="handleFocus"
          @blur="handleBlur"
        >
        </textarea>
        <div>
          <VACIcon v-if="appendIcon && appendIcon !== '' && !$slots.append" :name="appendIcon" />
          <slot name="append"></slot>
        </div>
      </div>
    </div>
    <div v-if="maxLength" :class="maxLengthClasses">
      {{ `${currentLength} / ${maxLength}` }}
    </div>
    <p v-if="validationErrors && validationErrors.length > 0" class="vac-textarea__error">
      <span v-for="error in validationErrors" :key="error">
        <VACIcon class="vac-textarea__error-icon" name="alert" />
        {{ error }} <br />
      </span>
    </p>
  </div>
</template>
<script setup>
import { computed, defineProps, ref, onMounted } from 'vue-demi';
import VACIcon from './VACIcon.vue';
import useVModelBindings, { vModelProps, vModelEmits } from '../composable/useVModelBindings';

const BLOCK_SELECTOR = 'vac-textarea';

const props = defineProps({
  /**
   * Label of the input
   */
  label: {
    type: String,
    required: true
  },
  /**
   * HTML name
   */
  name: {
    type: String,
    required: true
  },
  /**
   * HTML Placeholder
   */
  placeholder: {
    type: String
  },
  /**
   * HTML Disable
   */
  disabled: {
    type: Boolean
  },
  /**
   * HTML Required
   */
  required: {
    type: Boolean
  },
  /**
   * HTML Readonly
   */
  readonly: {
    type: Boolean
  },
  /**
   * Is label hidden
   */
  hideLabel: {
    type: Boolean
  },
  /**
   * Name of the icon to inject at the end of input
   */
  appendIcon: {
    type: String
  },
  /**
   * Rules for validation (value: String|Number): string[] | true
   * Takes values as input
   * If return true: no error
   * If return array of string : error
   */
  rules: {
    type: Array,
    default: () => []
  },
  /**
   * Error message to display
   */
  errorMessage: {
    type: String
  },
  /**
   * Max length to set and show
   */
  maxLength: {
    type: Number,
    default: undefined
  },
  /**
   * Current length from parent binding. Required if maxLength is set.
   */
  currentLength: {
    type: Number,
    default: 0
  },
  ...vModelProps
});

const emits = defineEmits([...vModelEmits, 'overflowing', 'extended']);
const binding = useVModelBindings(props, emits);

const input = ref(null);
const isActive = ref(false);
const hasBeenBlurred = ref(false);

const validationErrors = computed(() => {
  if (props.errorMessage) {
    return [props.errorMessage];
  }
  if (!props.rules) {
    return [];
  }
  if (hasBeenBlurred.value) {
    return props.rules.reduce((acc, rule) => {
      const rez = rule(binding.value);
      if (typeof rez === 'boolean' && rez === true) {
        return acc;
      }
      if (!acc.includes(rez)) {
        return [...acc, rez];
      }
      return acc;
    }, []);
  }
});

function handleFocus() {
  isActive.value = true;
}

function handleBlur() {
  isActive.value = false;
  hasBeenBlurred.value = true;
  if (isOverflowing.value) {
    emits('overflowing');
  }
}

const isOverflowing = computed(() => props.currentLength > props.maxLength);

function handleHeight() {
  input.value.style.height = `auto`;
  input.value.style.height = `${input.value.scrollHeight}px`;
  emits('extended');
}
const containerClasses = computed(() => [
  `${BLOCK_SELECTOR}__container`,
  {
    [`${BLOCK_SELECTOR}__container--active`]: isActive.value,
    [`${BLOCK_SELECTOR}__container--no-label`]: props.hideLabel,
    [`${BLOCK_SELECTOR}__container--disabled`]: props.disabled,
    [`${BLOCK_SELECTOR}__container--error`]: validationErrors.value && validationErrors.value.length > 0
  }
]);

const maxLengthClasses = computed(() => [
  `${BLOCK_SELECTOR}__max-length`,
  {
    [`${BLOCK_SELECTOR}__max-length--error`]: props.currentLength > props.maxLength
  }
]);

onMounted(() => {
  handleHeight();
});
</script>
<style scoped lang="postcss">
@import '../assets/css/_mixins.css';
.vac-textarea {
  &__container {
    @add-mixin input-container;
    position: relative;

    &--error {
      border-bottom-color: var(--vac-color-tabasco);
    }

    &--no-label {
      padding-top: 0.661rem;
      padding-bottom: 0.661rem;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__label {
    @add-mixin input-label;
  }

  &__input-container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }

  &__input {
    width: 100%;
    font-family: var(--vac-font-prelo);
    display: block;
    font-size: 1rem;
    border: 0;
    padding: 0;
    background-color: transparent;
    outline: 0;
    resize: none;
    overflow: hidden;
  }

  &__error {
    font-family: var(--vac-font-prelo);
    margin-top: 0;
    color: var(--vac-color-tabasco);
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    margin-bottom: 0.15rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    span {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  &__error-icon {
    fill: var(--vac-color-tabasco);
  }
  &__max-length {
    font-family: var(--vac-font-prelo);
    text-align: right;
    &--error {
      color: var(--vac-color-tabasco);
    }
  }
}
</style>
