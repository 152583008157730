<template>
  <div :class="blockClasses">
    <img class="vac-card__img" :src="image" :alt="alt" />
    <VACButton
      class="vac-card__button"
      appearance="primary"
      :label="button.label"
      :href="button.link"
      icon="arrow-right"
      @click.prevent="handleClick(button.link)"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue-demi';
import VACButton from './VACButton.vue';

const BASE_CLASS = 'vac-card';
const props = defineProps({
  /**
   * Card image link
   */
  image: {
    type: String
  },
  /**
   * Card image alt text
   */
  alt: {
    type: String
  },
  /**
   * Button label and link
   */
  button: {
    type: Object
  }
});
const emit = defineEmits(['card']);

const blockClasses = computed(() => [
  BASE_CLASS,
  {
    [`${BASE_CLASS}--disabled`]: !props.button.link
  }
]);

const handleClick = (link) => {
  if (link.includes('#services')) {
    emit('card', link)
  } else {
    window.open(link, '_blank');
  }
}
</script>

<style scoped lang="postcss">
.vac-card {
  display: flex;
  aspect-ratio: 31 / 25;
  max-width: 19.375rem;
  max-height: 15.625rem;
  position: relative;
  margin-bottom: 1.6rem;

  &__button {
    position: absolute;
    width: 80%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
