<template>
  <div :class="blockClasses" @click="handleClick">
    <div class="vac-dropdown__content" ref="dropdownRef">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue-demi';

import useClickOut from '../composable/useClickOut';

const BASE_CLASS = 'vac-dropdown';
const dropdownRef = ref(null);
const emit = defineEmits(['clickOut', 'expanded']);
const props = defineProps({
  /**
   * Dropdown Appearance (`primary`, `simple`)
   * @values primary, simple
   */
  appearance: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'simple'].includes(value)
  },
  /**
   * Is a child element of a parent dropdown
   */
  isChild: { type: Boolean },
  /**
   * Dropdown is expanded
   */
  expanded: { type: Boolean },
  /**
   * Dropdown is below an input (multi-select ...)
   */
  input: { type: Boolean },
  /**
   * Dropdown pushes siblings
   */
  accordion: { type: Boolean }
});

const blockClasses = computed(() => [
  BASE_CLASS,
  `${BASE_CLASS}--${props.appearance}`,
  {
    [`${BASE_CLASS}--expanded`]: props.expanded,
    [`${BASE_CLASS}--child`]: props.isChild,
    [`${BASE_CLASS}--input`]: props.input,
    [`${BASE_CLASS}--accordion`]: props.accordion
  }
]);
const { handleClickOut } = useClickOut(dropdownRef, emit, 'clickOut');

const dropdownHeight = ref(0);
let resizeObserver = undefined;

function resizeDropdown() {
  resizeObserver = new ResizeObserver(function (entries) {
    for (const entry of entries) {
      if (props.expanded) {
        dropdownHeight.value = `${entry.target.scrollHeight}px`;
      } else {
        if (dropdownRef.value) {
          dropdownRef.value.parentElement.style.transitionDuration = '0.3s';
        }
        dropdownHeight.value = '0px';
      }
    }
  });

  resizeObserver.observe(dropdownRef.value);
}

function handleClick(event) {
  handleClickOut(event);
  if (!props.isChild && props.expanded && dropdownRef) {
    dropdownRef.value.parentElement.style.transitionDuration = '0.025s';
  }
}

watch(
  () => props.expanded,
  () => {
    resizeDropdown();
  }
);
</script>

<style lang="postcss">
.vac-dropdown {
  position: absolute;
  z-index: 9;
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: v-bind(dropdownHeight);
  overflow: hidden;
  overflow-y: scroll;
  transition: max-height 0.3s ease;

  &--primary {
    background: var(--vac-color-light-white);
    border-top-color: var(--vac-color-white);
    border-bottom: 3px solid var(--vac-color-black);
  }
  &--simple {
    position: relative;
    transition: max-height 0.3s ease;
  }
  &--top {
    top: inherit;
    bottom: 100%;
    border-top: 3px solid var(--vac-color-black);
    border-bottom: 1px solid var(--vac-color-white);
  }

  &--input {
    top: calc(100% + 3px);
  }

  &--accordion {
    position: relative;
    top: 3px;
  }

  &__content {
    padding: 0.825rem;
  }
}
</style>
