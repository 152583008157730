<template>
  <div class="vac-multi-select">
    <VACButton
      :class="buttonClasses"
      appearance="input"
      search-box
      icon=""
      @click="handleExpanded($event)"
    >
      <div class="vac-multi-select__search-container">
        <label for="searchbox" class="vac-multi-select__label">{{ label }}</label>
        <input
          type="text"
          id="searchbox"
          name="searchbox"
          :placeholder="placeholder"
          v-model="searchInput"
          class="vac-multi-select__search-input"
          ref="searchfield"
          @input="updateOptions"
          @focus.stop="expanded = true"
        />
      </div>

      <div class="vac-multi-select__indicators">
        <span v-if="selectedOptions.length > 0" class="vac-multi-select__count">
          {{ selectedOptions.length }}
        </span>
        <button class="vac-multi-select__icon-indicator" @click.stop="resetField">
          <VACIcon :class="buttonIcon" :name="fieldIcon" />
        </button>
      </div>
    </VACButton>
    <VACDropdown
      :expanded="expanded"
      @click-out="expanded = false"
      :key="name"
      :input="input"
      :accordion="accordion"
    >
      <div class="vac-multi-select__options-list">
        <template v-for="check of selectOptions">
          <VACMultiSelectGroup
            v-if="check.options"
            :key="check.label"
            :options="check.options"
            :name="name"
            :category="check.label"
            @input="handleGroupChange($event)"
            :accordion="accordion"
            @update:modelValue="handleGroupChange($event)"
          />
          <VACCheckbox
            v-else
            :key="check.value"
            :name="name"
            :label="check.label"
            :input-value="check.value"
            v-model="binding[check.value]"
            @input="() => handleChange(check.value)"
            @update:modelValue="() => handleChange(check.value)"
          />
        </template>
      </div>
    </VACDropdown>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue-demi';
import VACButton from './VACButton.vue';
import VACIcon from './VACIcon.vue';
import VACDropdown from './VACDropdown.vue';
import VACCheckbox from './VACCheckbox.vue';
import VACMultiSelectGroup from './VACMultiSelectGroup.vue';
import { vModelEmits, vModelProps } from '../composable/useVModelBindings';

const BASE_CLASS = 'vac-multi-select';

const props = defineProps({
  /**
   * Label
   */
  label: {
    type: String
  },
  /**
   * Options list
   */
  options: { type: Array },
  /**
   * Field name
   */
  name: { type: String },
  /**
   * Placeholder
   */
  placeholder: { type: String },
  /**
   * Dropdown can push other content
   */
  accordion: { type: Boolean },
  input: { type: Boolean },
  ...vModelProps
});
const expanded = ref(false);
const selectedOptions = ref([]);
const searchInput = ref(null);
const filteredOptions = ref([]);

/* CLASSES */
const buttonClasses = computed(() => [
  `${BASE_CLASS}__button`,
  {
    [`${BASE_CLASS}__button--expanded`]: expanded.value
  }
]);
const buttonIcon = computed(() => [
  `${BASE_CLASS}__button-icon`,
  `${BASE_CLASS}__button-icon--${fieldIcon.value}`
]);

const fieldIcon = computed(() =>
  searchInput.value && searchInput.value.length > 0 ? 'cross' : 'arrow-right'
);

/* SETUP OPTIONS */
const selectOptions = computed(() =>
  filteredOptions.value.length > 0 ? filteredOptions.value : props.options
);

/* SEARCH */
function updateOptions() {
  filteredOptions.value = [];
  props.options.forEach((option) => {
    if (
      !option.options &&
      option['label'].toLowerCase().includes(searchInput.value.toLowerCase())
    ) {
      filteredOptions.value.push(option);
    } else if (option.options) {
      let optionCategory = { category: option.label, options: [] };
      option.options.forEach((suboption) => {
        if (suboption['label'].toLowerCase().includes(searchInput.value.toLowerCase())) {
          optionCategory.options.push(suboption);
        }
      });
      if (optionCategory.options.length > 0) {
        filteredOptions.value.push(optionCategory);
      }
    }
  });
}

/* BINDINGS MULTISELECT */
const isVue3PropUsed = computed(() => typeof props.modelValue !== 'undefined');

const emits = defineEmits(vModelEmits);

const binding = computed(() => {
  const allOptions = {};
  props.options.forEach((obj) => {
    if (!obj.options) {
      allOptions[obj.value] = false;
    } else {
      obj.options.forEach((option) => (allOptions[option.value] = false));
    }
  });
  return allOptions;
});

function updateSelected() {
  selectedOptions.value = Object.entries(binding.value)
    .filter(([key, value]) => value === true)
    .map(([key, value]) => ({
      [key]: value
    }));
}

/* HANDLERS */
function handleChange() {
  updateSelected();
  emits(isVue3PropUsed.value ? 'update:modelValue' : 'input', binding.value);
}
function handleGroupChange(payload) {
  for (let [key, value] of Object.entries(payload)) {
    binding.value[key] = value;
  }
  updateSelected();
  emits(isVue3PropUsed.value ? 'update:modelValue' : 'input', binding.value);
}

function handleExpanded(event) {
  if (event.target.id !== 'searchbox') {
    expanded.value = !expanded.value;
  }
}

function resetField() {
  if (searchInput.value && searchInput.value.length > 0) {
    searchInput.value = null;
  } else {
    expanded.value = !expanded.value;
  }
}
</script>
<style lang="postcss">
@import '../assets/css/_mixins.css';
.vac-multi-select {
  $block-selector: '.vac-multi-select';
  display: block;
  width: 100%;
  outline: none;
  border: none;
  background: none;
  position: relative;

  &__indicators {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    & :deep(.vac-icon) {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: var(--vac-color-primary);
    color: var(--vac-color-white);
  }
  &__selected {
    display: block;
    color: var(--vac-color-dove-gray);
    width: 100%;
    line-height: 1.2;
  }

  &__options-list {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
  }

  &__label {
    @add-mixin input-label;
    text-align: left;
    text-transform: initial;
  }

  &__search-container {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  &__search-input {
    border: none;
    background-color: transparent;
    font-size: 1.125rem;
    &::placeholder {
      font-family: var(--vac-font-prelo);
      color: var(--vac-color-dove-gray);
      font-weight: 300;
    }
  }

  &__icon-indicator {
    background: transparent;
    width: fit-content;
    height: fit-content;
    border: none;
    padding: 0;
    transition: transform 0.3s;

    .vac-multi-select__button--expanded & {
      transform: rotateZ(90deg);
    }
  }
}
</style>
