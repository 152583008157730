<template>
  <div :class="blockClasses">
    <label class="vac-select__label" :for="name">{{ label }}</label>
    <select
      v-model="binding"
      class="vac-select__select"
      :name="name"
      :id="name"
      :disabled="disabled"
      @focus="handleFocus"
      @blur="handleBlur"
      :required="required"
    >
      <option v-for="option in options" :key="`${name}-${option.value}`" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>
<script setup>
import { defineProps, ref, computed } from 'vue-demi';
import useVModelBindings, { vModelEmits, vModelProps } from '../composable/useVModelBindings';

const BLOCK_SELECTOR = 'vac-select';

const props = defineProps({
  /**
   * Label
   */
  label: {
    type: String,
    required: true
  },
  /**
   * HTML name attribute
   */
  name: {
    type: String,
    required: true
  },
  /**
   * HTML required
   */
  required: {
    type: Boolean
  },
  /**
   * Options : array of object : { label: `label`, value: `value` }
   */
  options: {
    type: Array,
    required: true,
    validator: (value) =>
      value.every(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, 'label') &&
          Object.prototype.hasOwnProperty.call(item, 'value')
      )
  },
  /**
   * HTML disabled
   */
  disabled: {
    type: Boolean
  },
  ...vModelProps
});

const emits = defineEmits(vModelEmits);
const binding = useVModelBindings(props, emits);

const isFocused = ref(false);

const blockClasses = computed(() => [
  BLOCK_SELECTOR,
  {
    [`${BLOCK_SELECTOR}--active`]: isFocused.value,
    [`${BLOCK_SELECTOR}--disabled`]: props.disabled
  }
]);

function handleFocus() {
  isFocused.value = true;
}

function handleBlur() {
  isFocused.value = false;
}
</script>
<style scoped lang="postcss">
@import '../assets/css/_mixins.css';
.vac-select {
  @add-mixin input-container;
  border-width: 0;

  &--active {
    outline: var(--vac-color-cornflower-blue) solid 1px;
  }

  &--disabled {
    opacity: 0.5;
  }

  &__label {
    @add-mixin input-label;
  }

  &__select {
    appearance: auto;
    border: 0;
    background-color: transparent;
    font-family: var(--vac-font-prelo);
    font-size: 1rem;
    padding: 0;
    min-width: 8rem;
    width: 100%;
    outline: 0;
    cursor: pointer;
  }
}
</style>
