<template>
  <div :class="blockClasses">
    <label class="vac-phone__label" :for="name">{{ label }}</label>
    <div class="vac-phone__content">
      <div class="vac-phone__select-container">
        <select
          name="prefix"
          v-model="selectedPrefix"
          class="vac-phone__country-code"
          :disabled="disabled"
          @change="updateValue"
        >
          <option
            v-for="(option, index) of countryCodes"
            :key="`country-codes--${index}`"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <VACIcon name="arrow-down" class="vac-phone__select-icon" />
      </div>
      <div class="vac-phone__separator"></div>
      <input
        type="tel"
        :name="name"
        :id="name"
        v-model="phoneNumber"
        :disabled="disabled"
        class="vac-phone__input"
        @change="updateValue"
      />
    </div>
  </div>
</template>
<script setup>
import VACIcon from './VACIcon.vue';
import { defineProps, ref, computed } from 'vue-demi';
import { vModelEmits } from '../composable/useVModelBindings';

const BLOCK_SELECTOR = 'vac-phone';

const props = defineProps({
  /**
   * Label
   */
  label: {
    type: String,
    required: true
  },
  /**
   * HTML name attribute
   */
  name: {
    type: String,
    required: true
  },
  /**
   * HTML required
   */
  required: {
    type: Boolean
  },
  /**
   * HTML disabled
   */
  disabled: {
    type: Boolean
  },
  /**
   * Country codes list
   */
  countryCodes: {
    type: Array,
    default: () => []
  }
});
const isFocused = ref(false);
const emits = defineEmits(vModelEmits);

const phoneNumber = ref('');

const blockClasses = computed(() => [
  BLOCK_SELECTOR,
  {
    [`${BLOCK_SELECTOR}--active`]: isFocused.value,
    [`${BLOCK_SELECTOR}--disabled`]: props.disabled
  }
]);

const selectedPrefix = ref('');

const formattedNumber = computed(() => {
  return phoneNumber.value[0] === '0' ? phoneNumber.value.slice(1) : phoneNumber.value;
});

function updateValue() {
  const isVue3PropUsed = computed(() => typeof props.modelValue !== 'undefined');
  const newValue = { prefix: selectedPrefix.value, number: formattedNumber.value };
  if (isVue3PropUsed.value) {
    emits('update:modelValue', newValue);
  } else {
    emits('input', newValue);
  }
}

function handleFocus() {
  isFocused.value = true;
}

function handleBlur() {
  isFocused.value = false;
}
</script>
<style scoped lang="postcss">
@import '../assets/css/_mixins.css';
.vac-phone {
  @add-mixin input-container;
  border-width: 0;
  max-height: 4.375rem;

  &--active {
    outline: var(--vac-color-cornflower-blue) solid 1px;
  }

  &--disabled {
    opacity: 0.5;
  }

  &__label {
    @add-mixin input-label;
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    gap: 0.5rem;
  }

  &__select-container {
    position: relative;
    display: grid;
    grid-template-areas: 'box';
  }

  &__select-icon {
    height: 0.75rem;
    grid-area: box;
    place-self: center end;
    pointer-events: none;
  }

  &__country-code {
    background: transparent;
    border-width: 0;
    border-radius: 0;
    width: 4rem;
    font-size: 1rem;
    padding: 0.35rem 0.5rem 0.35rem 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    grid-area: box;
    place-self: stretch;
    cursor: pointer;

    &::-ms-expand {
      display: none;
    }
  }

  &__input {
    background: transparent;
    border: none;
    border-radius: 0;
    padding-left: 0.5rem;
    flex-grow: 1;
    font-size: 1rem;
  }

  &__separator {
    margin-bottom: 0.2rem;
    border: 1px solid var(--vac-color-black);
  }
}
</style>
