<template>
  <div class="vac-tabs">
    <div class="vac-tabs__heading">
      <VACTabsMenu :tabs="tabs" :active-tab="tabs[activeIndex]" @switch="switchTab($event)" />
    </div>
    <div class="vac-tabs__body">
      <component :is="tabs[activeIndex].component" v-bind="tabs[activeIndex].props"></component>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue-demi';
import VACTabsMenu from './VACTabsMenu.vue';
const props = defineProps({
  /**
   * Tabs list
   */
  tabs: {
    type: Array
  }
});
const activeIndex = ref(0);

const switchTab = (payload) => {
  activeIndex.value = payload;
};
</script>

<style scoped lang="postcss">
@import '../assets/css/_breakpoints.css';
@import '../assets/css/_mixins.css';
.vac-tabs {
  width: 100%;
  &__body {
    padding-top: 1.5rem;
    @add-mixins breakpoint-tablet {
      padding-top: 2.5rem;
    }
  }
}
</style>
