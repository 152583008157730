<template>
  <div :class="classes">
    <div class="vac-checkbox__container">
      <input
        class="vac-ui-sr-only vac-checkbox__input"
        :id="`${name}-${inputValue}`"
        :name="name"
        :value="inputValue"
        type="checkbox"
        v-model="vModel"
      />
      <label class="vac-checkbox__label" :for="`${name}-${inputValue}`">
        <div class="vac-checkbox__check">
          <VACIcon class="vac-checkbox__icon" :name="iconName" :white="tag" />
        </div>
        {{ label }}
      </label>
      <slot name="toggler-content"></slot>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue-demi';
import useVModelBindings, { vModelProps, vModelEmits } from '../composable/useVModelBindings';
import VACIcon from './VACIcon.vue';

const BLOCK_SELECTOR = 'vac-checkbox';

const props = defineProps({
  /**
   * html `name` value
   */
  name: {
    type: String,
    required: true
  },
  /**
   * Label
   */
  label: {
    type: String
  },
  /**
   * Is toggle style
   */
  toggle: {
    type: Boolean
  },
  /**
   * Value for input
   */
  inputValue: {
    type: String || Boolean,
    required: true
  },
  /**
   * Is tag style
   */
  tag: {
    type: Boolean
  },
  /**
   * Is categoryToggler style
   */
  categoryToggler: {
    type: Boolean
  },
  partial: {
    type: Boolean
  },
  ...vModelProps
});

const emits = defineEmits(vModelEmits);

const vModel = useVModelBindings(props, emits);

const classes = computed(() => [
  BLOCK_SELECTOR,
  {
    [`${BLOCK_SELECTOR}--toggle`]: props.toggle,
    [`${BLOCK_SELECTOR}--tag`]: props.tag,
    [`${BLOCK_SELECTOR}--category`]: props.categoryToggler
  }
]);

const iconName = computed(() => (props.partial ? 'minus' : 'check'));
</script>
<style scoped lang="postcss">
.vac-checkbox {
  $block-selector: .vac-checkbox;
  font-family: var(--vac-font-prelo);

  &--toggle {
    $(block-selector) {
      &__check {
        position: relative;
        width: 1.875rem;
        height: 1.125rem;
        border-radius: 6.25rem;
        padding-left: 2px;
        padding-right: 2px;
      }

      &__icon {
        background-color: var(--vac-color-black);
        border-radius: 50%;
        fill: var(--vac-color-black);
        width: 0.75rem;
        height: 0.75rem;
        transform: translateX(-5px);
        transition: all 250ms;
        padding: 1px;
      }

      &__input:checked + $(block-selector)__label {
        $(block-selector) {
          &__check {
            background-color: var(--vac-color-white);
            justify-content: flex-end;
          }

          &__icon {
            left: auto;
            transform: translateX(0);
            background-color: var(--vac-color-sushi);
            fill: var(--vac-color-white);
          }
        }
      }
    }
  }

  &--tag {
    $(block-selector) {
      &__label {
        display: inline-flex;
        border: 1px solid var(--vac-color-black);
        height: 2.5rem;
        padding-left: 1.375rem;
        padding-right: 1.375rem;
        align-items: center;
        gap: 0;
        transition: 250ms;
        font-weight: bold;
        color: var(--vac-color-black);
        font-size: 0.75rem;
      }

      &__check {
        border: 0;
        width: 0;
        transition: all 250ms;
        background-color: transparent;
      }

      &__input:checked + {
        $(block-selector)__label {
          background-color: var(--vac-color-black);
          color: var(--vac-color-white);
          padding-left: 0.625rem;
          padding-right: 0.625rem;
          gap: 0.625rem;

          $(block-selector) {
            &__check {
              width: 0.875rem;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &__input {
    &:checked + $(block-selector)__label {
      $(block-selector)__check {
        background-color: var(--vac-color-black);
        .vac-icon {
          fill: var(--vac-color-white);
        }
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    gap: 0.3125rem;
    cursor: pointer;
  }

  &__check {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid var(--vac-color-black);
    box-sizing: border-box;
    transition: background-color 250ms;
    .vac-icon {
      fill: transparent;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    cursor: pointer;
  }
}
</style>
