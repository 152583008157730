<template>
  <div :class="blockClasses">
    <div class="vac-radio-group__container">
      <VACRadio
        v-for="(option, index) of options"
        :key="`radio--${index}`"
        :name="name"
        :input-value="option.inputValue"
        :label="option.label"
        :appearance="appearance === 'video' ? 'video' : 'default'"
        :disabled="disabled"
        v-model="binding"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue-demi';
import VACRadio from './VACRadio.vue';
import useVModelBindings, { vModelProps, vModelEmits } from '../composable/useVModelBindings';

const BLOCK_SELECTOR = 'vac-radio-group';

const props = defineProps({
  /**
   * Group appearance options. Contains directions and video variant.
   */
  appearance: {
    type: String,
    default: 'horizontal',
    validator: (value) => ['vertical', 'horizontal', 'video'].includes(value)
  },
  /**
   * Switch name
   */
  name: {
    type: String
  },
  /**
   * Radio input options list
   */
  options: {
    type: Array,
    default: () => []
  },
  /**
   * Disabled
   */
  disabled: {
    type: Boolean
  },
  ...vModelProps
});

const emits = defineEmits(vModelEmits);

const binding = useVModelBindings(props, emits);

const blockClasses = computed(() => [BLOCK_SELECTOR, `${BLOCK_SELECTOR}--${props.appearance}`]);
</script>

<style scoped lang="postcss">
.vac-radio-group {
  $block-selector: .vac-radio-group;
  font-family: var(--vac-font-prelo);
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    gap: 1.25rem;
    $(block-selector)--vertical & {
      flex-direction: column;
      gap: 1.65rem;
    }
    $(block-selector)--video & {
      gap: 0;
      border-radius: 1.25rem;
      border: 1px solid var(--vac-color-white);
      align-items: stretch;
    }
  }
}
</style>
