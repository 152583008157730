<template>
  <div class="vac-multi-select-group">
    <VACCheckbox
      :class="togglerClasses"
      :name="category"
      inputValue="category"
      :partial="partialChecking"
      v-model="categoryBinding"
      @input="handleCategory()"
      @update:modelValue="handleCategory()"
    >
      <template #toggler-content>
        <VACButton
          appearance="discrete"
          icon="arrow-right"
          full-width
          class="vac-multi-select-group__toggler-content"
          @click="handleToggle"
          >{{ category }}</VACButton
        >
      </template>
    </VACCheckbox>

    <VACDropdown
      appearance="simple"
      @click-out="toggle = false"
      is-child
      :expanded="toggle"
      :accordion="accordion"
    >
      <div class="vac-multi-select-group__options-list">
        <VACCheckbox
          v-for="option of options"
          :key="option.value"
          :name="name"
          :label="option.label"
          :inputValue="option.value"
          v-model="binding[option.value]"
          @input="handleChange(option.value)"
          @update:modelValue="handleChange(option.value)"
        />
      </div>
    </VACDropdown>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue-demi';
import VACCheckbox from './VACCheckbox.vue';
import VACDropdown from './VACDropdown.vue';
import VACButton from './VACButton.vue';
import { vModelEmits, vModelProps } from '../composable/useVModelBindings';

const BASE_CLASS = 'vac-multi-select-group';
const props = defineProps({
  name: { type: String },
  options: {
    type: Array
  },
  category: { type: String },
  accordion: { type: Boolean },
  ...vModelProps
});

const toggle = ref(false);
const numberChecked = ref(0);
const categoryBinding = ref(false);

/* CLASSES */
const togglerClasses = computed(() => [
  `${BASE_CLASS}__toggler`,
  {
    [`${BASE_CLASS}__toggler--open`]: toggle.value
  }
]);

/* BINDING */
const isVue3PropUsed = computed(() => typeof props.modelValue !== 'undefined');

const emits = defineEmits(vModelEmits);

const binding = computed(() =>
  props.options.reduce((acc, item) => ({ ...acc, [item.value]: false }), {})
);

/* CHECKED */
const partialChecking = computed(
  () => categoryBinding.value && numberChecked.value < Object.values(binding.value).length
);

function updateNumber() {
  numberChecked.value = Object.values(binding.value).filter((item) => item).length;
}

/* HANDLERS */
function handleChange() {
  updateNumber();
  emits(isVue3PropUsed.value ? 'update:modelValue' : 'input', binding.value);
}
function handleCategory() {
  toggle.value = true;
  for (let [key, value] of Object.entries(binding.value)) {
    binding.value[key] = categoryBinding.value;
  }
  updateNumber();
  handleChange();
}
function handleToggle() {
  toggle.value = !toggle.value;
}
</script>
<style lang="postcss" scoped>
.vac-multi-select-group {
  display: flex;
  flex-direction: column;
  &__options-list {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    padding-left: 0.875rem;
  }
  &__toggler {
    border-bottom: 1px solid var(--vac-color-white);
    padding-bottom: 0.75rem;
    &:deep(.vac-button) {
      height: unset;
      width: 100%;
      justify-content: space-between;
    }
    &--open :deep(.vac-button__icon-container) {
      transform: rotate(90deg);
    }
  }

  &__toggler-content {
    text-transform: initial;
  }
}
</style>
