<template>
  <div :class="BASE_CLASS">
    <div class="vac-card-image-link-list__list">
      <VACCard
        v-for="(card, index) of cards"
        :key="`card-${index}`"
        :image="card.image"
        :alt="card.title"
        :button="{ label: card.title, link: card.url }"
        @card="$emit('link', $event)"
      />
    </div>
  </div>
</template>

<script setup>
import VACCard from './VACCard.vue';

const BASE_CLASS = 'vac-card-image-link-list';
defineProps({
  cards: {
    type: Array
  }
});
</script>

<style scoped lang="postcss">
@import '../assets/css/_breakpoints.css';
@import '../assets/css/_mixins.css';
.vac-card-image-link-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    @add-mixin breakpoint-tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @add-mixin breakpoint-desktop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
