<template>
  <section :class="classList">
    <VACButton
      discrete
      class="expandable-section__header"
      :class="togglerClass"
      v-bind="togglerAttr"
      icon=""
      @click="toggle"
    >
      <div class="expandable-section__header-content">
        <slot name="title"></slot>
        <slot name="title-link"></slot>
      </div>
      <div class="expandable-section__after">
        <VACIcon v-if="isOutlined" name="plus-thin" />
      </div>
    </VACButton>
    <transition :name="transitionName" mode="out-in">
      <div v-show="open" class="expandable-section__body">
        <slot name="body"></slot>
      </div>
    </transition>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue-demi';
import VACButton from './VACButton.vue';
import VACIcon from './VACIcon.vue';

const BASE_SELECTOR = 'expandable-section';

const props = defineProps({
  isOpen: {
    type: Boolean
  },
  transitionName: {
    type: String,
    default: `expandable-section-`
  },
  isOutlined: {
    type: Boolean
  },
  large: Boolean,
  togglerClass: { type: [String, Array, Object], default: '' },
  togglerAttr: { type: Object, default: null },
  headerSmallSpacing: { type: Boolean }
});

const open = ref(props.isOpen);

const emits = defineEmits(['toggle']);

const classList = computed(() => [
  BASE_SELECTOR,
  {
    [`${BASE_SELECTOR}--open`]: open.value,
    [`${BASE_SELECTOR}--outlined`]: props.isOutlined,
    [`${BASE_SELECTOR}--large`]: props.large,
    [`${BASE_SELECTOR}--small-heading`]: props.headerSmallSpacing
  }
]);

const toggle = () => {
  open.value = !open.value;
  emits('toggle', open.value);
};
</script>

<style scoped lang="postcss">
.expandable-section {
  $block-selector: .expandable-section;
  display: block;
  width: 100%;
  &--outlined {
    border-bottom: 1px solid var(--vac-color-alto-gray);
  }

  &--enter-active,
  &--leave-active {
    transition-duration: var(--default-transition-duration);
    transition-property: opacity, max-height;
  }

  &--enter,
  &--leave-to {
    opacity: 0;
  }

  &--enter-to,
  &--leave {
    opacity: 1;
  }

  &--open {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.29);
  }

  &--outlined {
    box-shadow: none;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 0.625em;
    background-color: var(--vac-color-light-white);
    color: var(--vac-color-cool-gray-10C);
    font-size: 1em;
    transition: padding 250ms;

    $(block-selector)--open & {
      background-color: transparent;
    }

    $(block-selector)--large & {
      padding: 2.25em;
      text-transform: uppercase;
      font-weight: bold;
    }

    $(block-selector)--small-heading$(block-selector)--large & {
      padding: 2.25em;
      height: unset;
    }
    $(block-selector)--small-heading$(block-selector)--large$(block-selector)--open & {
      padding: 2.25em 2.25em 1em;
    }

    $(block-selector)--outlined & {
      color: var(--vac-color-black);
      background: transparent;
      padding: 2.5em 0;
      font-family: var(--vac-font-prelo);
      font-size: 1rem;
      font-weight: bold;
    }
  }

  &__after {
    --arrow-size: 0.3em;
    display: inline-block;

    width: 0;
    height: 0;
    border-left: var(--arrow-size) solid transparent;
    border-right: var(--arrow-size) solid transparent;
    border-top: var(--arrow-size) solid currentColor;
    transition: transform var(--default-transition-duration);

    $(block-selector)--open & {
      transform: rotate(180deg);
    }

    $(block-selector)--outlined & {
      border: 0;
      width: 1rem;
      height: 1rem;
    }
  }

  &__header-content {
    display: grid;
    place-items: start;
    gap: 0.5rem;
  }

  &__body {
    padding: 1em 1.25em;
    font-family: var(--vac-font-prelo);

    $(block-selector)--large & {
      padding: 0.5em 2.25em 2.25em;
    }

    $(block-selector)--outlined & {
      padding: 1em 0;
    }
  }
}
</style>
