<template>
  <div :class="blockClasses">
    <p class="vac-stepper__label">{{ current }} / {{ max }}</p>
    <div class="vac-stepper__steps">
      <div v-for="index in max" :key="index" :class="getClassForStep(index)"></div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue-demi';

const BLOCK_SELECTOR = 'vac-stepper';

const props = defineProps({
  /**
   * Maximum step
   */
  max: {
    type: Number,
    required: true
  },
  /**
   * Active step
   */
  current: {
    type: Number,
    required: true
  },
  /**
   * Center counter
   */
  center: {
    type: Boolean
  }
});

const blockClasses = computed(() => ['vac-stepper', { 'vac-stepper--center': props.center }]);

const getClassForStep = (index) => [
  `${BLOCK_SELECTOR}__step`,
  { [`${BLOCK_SELECTOR}__step--active`]: index <= props.current }
];
</script>
<style scoped lang="postcss">
.vac-stepper {
  $block-selector: .vac-stepper;
  font-family: var(--vac-font-prelo);
  font-size: 0.75rem;

  &--center {
    $(block-selector)__label {
      text-align: center;
    }
  }

  &__label {
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  &__steps {
    display: flex;
    gap: 0.25rem;
  }

  &__step {
    position: relative;
    width: 2rem;
    background-color: var(--vac-color-cool-gray-6U);
    height: 2px;

    &::before {
      content: '';
      background-color: var(--vac-color-black);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      transition: all 1s;
    }

    &--active {
      &::before {
        width: 100%;
      }
    }
  }
}
</style>
