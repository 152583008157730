<template>
  <transition :name="transitionName" appear>
    <div v-if="isOpen" :class="blockClasses">
      <div class="vac-side-modal__overlay" @click="handleClose"></div>
      <div class="vac-side-modal__container">
        <VACButton
          class="vac-side-modal__close"
          appearance="discrete"
          icon="close"
          @click="handleClose"
        />
        <div class="vac-side-modal__content-wrapper">
          <div class="vac-side-modal__heading">
            <div v-if="title" class="vac-side-modal__title">{{ title }}</div>
            <div v-if="count" class="vac-side-modal__count">
              <span v-for="(item, index) of countValues" :key="`count-${index}`">
                {{ item }}
              </span>
            </div>
          </div>
          <div class="vac-side-modal__content">
            <slot></slot>
          </div>
        </div>

        <div class="vac-side-modal__buttons">
          <VACButton
            v-if="canReset"
            @click="reset"
            appearance="secondary"
            full-width
            :label="resetLabel"
          />
          <VACButton
            v-if="!canReset"
            @click="handleClose"
            appearance="secondary"
            full-width
            :label="cancelLabel"
          />
          <VACButton
            @click="submit"
            :disabled="disabled"
            appearance="primary"
            full-width
            :label="okLabel"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed } from 'vue-demi';
import VACButton from './VACButton.vue';
const props = defineProps({
  /**
   * Modal main title
   */
  title: {
    type: String
  },
  /**
   * Elements count for filter modal (XX stories, XX items ...)
   */
  count: {
    type: [String, Array]
  },
  /**
   * Modal is open
   */
  isOpen: {
    type: Boolean,
    default: false
  },
  /**
   * Modal has reset button instead of cancel
   */
  canReset: {
    type: Boolean
  },
  /**
   * Modal opens on the right
   */
  right: {
    type: Boolean
  },
  /**
   * Submit button is disabled
   */
  disabled: {
    type: Boolean
  },
  /**
   * Cancel button label
   */
  cancelLabel: {
    type: String
  },
  /**
   * Ok button label
   */
  okLabel: {
    type: String
  },
  /**
   * Reset button label
   */
  resetLabel: {
    type: String
  }
});
const BASE_CLASS = 'vac-side-modal';
const emits = defineEmits(['close', 'apply', 'reset']);
const transitionName = computed(() => (props.right ? 'slide-right' : 'slide-left'));
const blockClasses = computed(() => [
  BASE_CLASS,
  {
    [`${BASE_CLASS}--right`]: props.right
  }
]);

const countValues = computed(() => (Array.isArray(props.count) ? props.count : [props.count]));

function handleClose() {
  emits('close');
}
function submit() {
  emits('apply');
}
function reset() {
  emits('reset');
}
</script>

<style scoped lang="postcss">
@import '../assets/css/_mixins.css';
.vac-side-modal {
  $block-selector: 'vac-side-modal';
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  z-index: 10;
  font-family: var(--vac-font-prelo);
  &--right {
    @add-mixin breakpoint-desktop {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  &__overlay {
    background: var(--vac-color-black);
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
  &__container {
    position: relative;
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    opacity: 1;
    transform: translateX(0);
    height: 100%;
    width: 100vw;
    background-color: var(--vac-color-white);
    z-index: 1;
    padding: 3rem 1rem 1rem;
    gap: 1rem;
    display: grid;
    grid-template-rows: calc(100% - 9rem) 5rem;
    grid-template-areas: 'main' 'footer';
    @add-mixin breakpoint-desktop {
      display: flex;
      flex-direction: column;
      width: 40vw;
      padding: 3rem 2rem;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 1rem;
  }

  &__content-wrapper {
    grid-area: main;
  }

  &__content {
    height: 100%;
    overflow: auto;
    padding-bottom: 1rem;
    @add-mixin breakpoint-desktop {
      padding-bottom: 0;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    grid-area: footer;
    padding-top: 1rem;
    background-color: var(--vac-color-white);
    @add-mixin breakpoint-desktop {
      flex-direction: row;
    }
  }

  &__count {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    @add-mixin breakpoint-desktop {
      flex-direction: row;
    }
  }
}
.slide-left-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
}

.slide-left-enter,
.slide-left-leave-to {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  opacity: 0;
  .vac-side-modal__container {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-right-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
}

.slide-right-enter,
.slide-right-leave-to {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  opacity: 0;
  .vac-side-modal__container {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
