<template>
  <component
    :is="blockTag"
    :href="href"
    :target="target"
    :class="blockClasses"
    :disabled="disabled"
    :to="to"
    :type="type"
    @click="handleClick"
  >
    <template v-if="label">
      <span class="vac-button__label">
        {{ label }}
      </span>
    </template>
    <template v-else-if="$slots.default">
      <slot />
    </template>
    <div v-if="icon" class="vac-button__icon-container">
      <VACIcon
        class="vac-button__icon vac-button__icon--first"
        :name="icon"
        :white="appearance === 'primary'"
        :small="!isIconSolo"
      />
      <VACIcon
        class="vac-button__icon vac-button__icon--last"
        :name="icon"
        :white="appearance === 'primary'"
        :small="!isIconSolo"
      />
    </div>
    <div v-if="badge" class="vac-button__badge">{{ badge }}</div>
  </component>
</template>
<script setup>
import { defineProps, computed, useSlots } from 'vue-demi';
import VACIcon from './VACIcon.vue';

const BLOCK_SELECTOR = 'vac-button';

const props = defineProps({
  /**
   * Button label
   */
  label: {
    type: String
  },
  /**
   * Button Appearance (`primary`, `secondary`, `link`)
   * @values primary, secondary, link
   */
  appearance: {
    type: String,
    default: 'primary',
    validator: (value) =>
      ['primary', 'secondary', 'link', 'input', 'discrete', 'link-discrete'].includes(value)
  },
  /**
   * Name of the icon to append
   */
  icon: {
    type: String,
    default: 'arrow-right'
  },
  /**
   * Is disabled
   */
  disabled: {
    type: Boolean
  },
  /**
   * Is small font
   */
  small: {
    type: Boolean
  },
  /**
   * Is in search box
   */
  searchBox: {
    type: Boolean
  },
  /**
   * External Href link
   */
  href: {
    type: String
  },
  /**
   * `a` href target
   */
  target: {
    type: String
  },
  /**
   * Vue Router link
   */
  to: {
    type: [String, Object]
  },
  /**
   * Center the text inside button
   */
  center: {
    type: Boolean
  },
  /**
   * Condensed font
   */
  condensed: {
    type: Boolean
  },
  /**
   * Discrete
   */
  discrete: {
    type: Boolean
  },
  /**
   * Danger
   */
  danger: {
    type: Boolean
  },
  /**
   * Disable uppercase
   */
  normalText: {
    type: Boolean
  },
  /**
   * Full width
   */
  fullWidth: {
    type: Boolean
  },
  /**
   * Badge number
   */
  badge: {
    type: Number
  },
  /**
   * Content is reversed (icon at left)
   */
  reverse: {
    type: Boolean
  },
  /**
   * HTML Type
   */
  type: {
    type: String
  },
  /**
   * Label is underlined
   */
  underlinedLabel: {
    type: Boolean
  }
});

const blockTag = computed(() => (props.href ? 'a' : props.to ? 'router-link' : 'button'));
const slots = useSlots();

const isIconSolo = computed(() => !props.label && !!props.icon && !slots.default);
const hasLabel = computed(() => props.label && !slots.default);
const blockClasses = computed(() => [
  BLOCK_SELECTOR,
  `${BLOCK_SELECTOR}--${props.appearance}`,
  {
    [`${BLOCK_SELECTOR}--with-icon`]: props.icon,
    [`${BLOCK_SELECTOR}--small`]: props.small,
    [`${BLOCK_SELECTOR}--disabled`]: props.disabled,
    [`${BLOCK_SELECTOR}--discrete`]: props.discrete,
    [`${BLOCK_SELECTOR}--center`]: props.center,
    [`${BLOCK_SELECTOR}--condensed`]: props.condensed,
    [`${BLOCK_SELECTOR}--normal-text`]: props.normalText,
    [`${BLOCK_SELECTOR}--full-width`]: props.fullWidth,
    [`${BLOCK_SELECTOR}--icon-solo`]: isIconSolo.value,
    [`${BLOCK_SELECTOR}--reverse`]: props.reverse,
    [`${BLOCK_SELECTOR}--danger`]: props.danger,
    [`${BLOCK_SELECTOR}--underlined-label`]: props.underlinedLabel,
    [`${BLOCK_SELECTOR}--searchbox`]: props.searchBox
  }
]);
const emits = defineEmits(['click']);

const handleClick = (event) => {
  emits('click', event);
};
</script>
<style scoped lang="postcss">
@import '../assets/css/_mixins.css';
.vac-button {
  $block-selector: .vac-button;
  --vac-ui-button-icon-size: 1rem;
  --vac-ui-button-icon-solo-size: 1.85rem;

  display: inline-flex;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  line-height: 0.875rem;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: var(--vac-color-black);
  background: transparent;
  text-transform: uppercase;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.1875rem;
  padding-bottom: 0.9375rem;
  font-family: var(--vac-font-prelo);
  font-size: 0.75rem;
  height: 3.125rem;
  min-width: 3.125rem;
  font-weight: var(--vac-font-weight-bold);
  position: relative;
  pointer-events: all;

  &:hover {
    $(block-selector) {
      &__icon {
        &--first {
          transform: translateX(200%);
          opacity: 0;
        }
        &--last {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  &--center {
    justify-content: center;
  }

  &--condensed {
    font-family: var(--vac-font-prelo-condensed);
    font-weight: bold;
  }

  &--reverse {
    flex-direction: row-reverse;
    padding-left: 0.25rem;

    &$(block-selector)--with-icon:not($(block-selector)--link) {
      padding-right: 1.25rem;
    }
  }

  &--normal-text {
    text-transform: none;
  }

  &--full-width {
    width: 100%;
  }

  &--primary {
    background-color: var(--vac-color-black);
    color: var(--vac-color-white);

    $(block-selector)__icon {
      fill: var(--vac-color-white);
    }
  }

  &--secondary {
    background-color: var(--vac-color-white);
    color: var(--vac-color-black);
  }

  &--discrete {
    padding: 0;
    background: none;
    font-weight: inherit;
    min-height: auto;
    border-color: transparent;
    color: var(--vac-color-black);
    justify-content: center;

    $(block-selector)__icon {
      fill: var(--vac-color-black);
    }
  }

  &--link {
    display: inline-flex;
    padding: 0;
    padding-bottom: 0.25rem;
    height: auto;
    border: 0;
    color: var(--vac-color-black);
    border-bottom: 1px solid var(--vac-color-black);
    font-size: 0.75rem;
    font-weight: var(--vac-font-weight-bold);
    transition-property: border-color, color;
    transition-duration: 0.3s;

    &:hover {
      color: var(--vac-color-primary);
      border-color: transparent;
    }

    $(block-selector) {
      &__icon-container {
        display: none;
      }
    }
  }

  &--underlined-label {
    border-bottom: none;
  }

  &--with-icon {
    gap: 1rem;
    padding-right: 0;
    $(block-selector) {
      &__icon-container {
        display: flex;
        width: 1rem;
      }
    }
  }

  &--link-discrete {
    font-size: 0.75rem;
    color: var(--vac-color-mine-shaft);
    text-decoration: none;
    border: none;
    padding: 0.25rem;
    text-transform: initial;
    font-weight: normal;
    &:hover {
      color: var(--vac-color-black);
      text-decoration: underline;
    }
  }

  &--input {
    @add-mixin input-container;
    border-width: 0 0 3px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    height: unset;
    padding-bottom: 0.75rem;
  }

  &--small {
    font-size: 0.75rem;
  }

  &--icon-solo {
    aspect-ratio: 1 / 1;
    padding: 0.15rem;
    justify-content: center;

    &:hover {
      $(block-selector) {
        &__icon--last {
          transform: translateX(-1.5rem);
        }
      }
    }

    $(block-selector) {
      &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: calc(var(--vac-ui-button-icon-solo-size) * 2);
      }

      &__icon {
        position: relative;
        top: 0;
        width: 1.85rem;
        height: 1.85rem;

        &--first {
          left: 0.75rem;
        }

        &--last {
          transform: translateX(-2.5rem);
        }
      }
    }
  }

  &--searchbox {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &__icon-container {
    overflow: hidden;
    position: relative;
    width: calc(var(--vac-ui-button-icon-size) * 3);
    height: 1rem;
    transform-origin: center center;
    transition: transform 0.125s;
  }

  &__icon {
    position: absolute;
    top: 3px;
    left: calc(50% - calc(var(--vac-ui-button-icon-size) / 2));
    transition: all 250ms;

    &--last {
      transform: translateX(-200%);
      opacity: 0;
    }
  }

  &__badge {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--vac-color-black);
    color: var(--vac-color-white);
    font-size: 0.75rem;
  }

  &--danger {
    color: var(--vac-color-tabasco);
  }

  &__label {
    $(block-selector)--underlined-label & {
      border-bottom: 1px solid var(--vac-color-black);
    }
  }
}
</style>
