<template>
    <div v-if="message" :class="blockClasses">
        <VACIcon v-if="currentStatus === STATUS.PENDING" name="alert" />
        <div v-if="currentStatus !== STATUS.PENDING" class="vac-notification-status__dot" />
        {{ message }}
    </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue-demi';
import VACIcon from './VACIcon.vue';

const STATUS = Object.freeze({
    PENDING: 'default',
    DENIED: 'denied',
    GRANTED: 'granted',
})

const props = defineProps({
    pendingMessage: {
        type: String,
        default: 'If you accept the notifications we will ask you to accept your browser permission after. You could change your preferences in your account later.'
    },
    deniedMessage: {
        type: String,
        default: 'Your browser notification permission is already rejected. Please go to your browser preferences to enable it.'
    },
    grantedMessage: {
        type: String,
        default: 'You already accepted browser permission to receive notification from The Hour Club.',
    }
});

const currentStatus = ref();

const getNotificationStatus = () => {
    currentStatus.value = Notification.permission;
}

const message = computed(() => {
    switch (currentStatus.value) {
        case STATUS.PENDING:
            return props.pendingMessage;
        case STATUS.DENIED:
            return props.deniedMessage;
        case STATUS.GRANTED:
            return props.grantedMessage;
        default:
            return undefined;
    }
});

const blockClasses = computed(() => ['vac-notification-status', `vac-notification-status--${currentStatus.value.toLowerCase()}`])

onMounted(() => {
    getNotificationStatus();
})

</script>
<style scoped lang="postcss">
.vac-notification-status {
    $block-selector: .vac-notification-status;
    font-family: var(--vac-font-prelo);
    gap: 0.5rem;

    &--denied,
    &--granted {
        background-color: var(--vac-color-light-white);
        padding: 0.875rem;
    }

    &__dot {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        display: inline-block;

        $(block-selector)--denied & {
            background-color: var(--vac-color-tabasco);
        }

        $(block-selector)--granted & {
            background-color: var(--vac-color-sushi);
        }
    }
}
</style>