<template>
  <div class="vac-snackbar">
    <transition-group name="snack">
      <div
        v-for="(snack, index) in snacks"
        :key="`snack-${index}`"
        :class="['vac-snackbar__snack', `vac-snackbar__snack--${snack.type}`]"
      >
        {{ snack.label }}
        <button v-if="snack.action" class="vac-snackbar__button" @click="snack.action">
          {{ snack.actionLabel }}
        </button>
      </div>
    </transition-group>
  </div>
</template>
<script setup>
import { ref } from 'vue-demi';
import { snackEmitter } from '../utils/SnackManager';
import GenerateRandomId from '../utils/GenerateRandomId';

const snacks = ref([]);
snackEmitter.on('newSnack', (snack) => {
  const uid = GenerateRandomId();
  snacks.value.push({ ...snack, uid });
  if (snack.timeout && snack.timeout > 0) {
    setTimeout(() => {
      snacks.value = [...snacks.value.filter((item) => item.uid !== uid)];
    }, snack.timeout);
  }
});
</script>
<style scoped lang="postcss">
.vac-snackbar {
  $block-selector: .vac-snackbar;
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &__snack {
    display: flex;
    align-items: center;
    font-family: var(--vac-font-prelo);
    font-size: 1.125rem;
    width: 100%;
    max-width: 22.5rem;
    padding: 1rem 0.625rem;
    background-color: var(--vac-color-light-white);
    justify-content: space-between;
  }

  &__button {
    border: 0;
    background: transparent;
    font-family: var(--vac-font-prelo-condensed);
    font-size: 1rem;
    cursor: pointer;
    transition: color 250ms;

    $(block-selector)__snack--success & {
      color: var(--vac-color-sushi);
    }

    $(block-selector)__snack--alert & {
      color: var(--vac-color-crown-of-thorns);
    }

    &:hover {
      color: var(--vac-color-black);
    }
  }
}

.snack {
  &-move,
  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease;
  }

  &-leave-active {
    position: absolute;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(calc(100% + 2rem));
  }
}
</style>
