<template>
  <div :class="['vac-checkbox-group', { 'vac-checkbox-group--tag': tag }]">
    <VACCheckbox
      v-for="check in options"
      :key="check.inputValue"
      :toggle="toggle"
      :tag="tag"
      :name="name"
      :label="check.label"
      :inputValue="check.inputValue"
      v-model="binding[check.inputValue]"
      @input="() => handleChange(check.inputValue)"
      @update:modelValue="() => handleChange(check.inputValue)"
    />
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue-demi';
import VACCheckbox from './VACCheckbox.vue';
import { vModelProps, vModelEmits } from '../composable/useVModelBindings';

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  },
  toggle: {
    type: Boolean
  },
  tag: {
    type: Boolean
  },
  ...vModelProps
});
const isVue3PropUsed = computed(() => typeof props.modelValue !== 'undefined');

const emits = defineEmits(vModelEmits);

const binding = computed(() =>
  props.options.reduce((acc, item) => ({ ...acc, [item.inputValue]: props.value[item.inputValue] ?? false }), {})
);

function handleChange(value) {
  emits(isVue3PropUsed.value ? 'update:modelValue' : 'input', {
    ...(isVue3PropUsed.value ? props.modelValue : props.value),
    [value]: binding.value[value]
  });
}
</script>
<style scoped lang="scss">
.vac-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &--tag {
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
</style>
